<template>
	<div>
		<div
			ref="text"
			class="cursor-help border-b border-dashed border-b-zinc-600"
			@click="showToolTip = true"
			@mouseenter="showToolTip = true"
			@mouseleave="showToolTip = false"
		>
			<slot name="text"></slot>
		</div>
		<div
			v-show="showToolTip"
			ref="toolTip"
			class="absolute -left-10 top-6 z-50 w-64 rounded bg-white p-4 text-xs shadow-md"
		>
			<slot name="message"></slot>
		</div>
	</div>
</template>

<script setup lang="ts">
import { onClickOutside, onKeyDown } from '@vueuse/core';

const toolTip = ref<HTMLDivElement | null>(null);
const showToolTip = ref(false);

onClickOutside(toolTip, () => (showToolTip.value = false));
onKeyDown('Escape', () => (showToolTip.value = false));
</script>
